import {Injectable} from '@angular/core';
import {Action, NgxsOnInit, Selector, State, StateContext, Store} from '@ngxs/store';
import {PersonService} from './person.service';
import {
  CreatePerson,
  CreatePersonSuccess,
  LoadCurrentPerson,
  LoadCurrentPersonSuccess,
  LoadPersons,
  LoadPersonsSuccess,
  RetrievePersonDetail,
  RetrievePersonDetailSuccess,
  SelectForSubmission
} from './person.actions';
import {Person} from '../../symbols';
import {BackendError} from '../../../messages/messages.actions';
import {LogoutSuccess} from '../../../auth/states/auth/auth.actions';
import {PersonStateModel} from './person.models';


@State<PersonStateModel>({
  name: 'persons',
  defaults: {
    loaded: false,
    loading: false,
    list: [],
    dict: {},
    selected: null,
    current: null,
    inSubmission: null,
  },
})

@Injectable()
export class PersonState implements NgxsOnInit {

  @Selector()
  static personChoices(state: PersonStateModel) {
    return state.list.map(personId => {
      const person = state.dict[personId];
      if (!person) {
        return null;
      }
      return [person.id, person.display_name || `person #${person.id}`];
    });
  }

  @Selector()
  static currentPerson(state: PersonStateModel): Person {
    return state.current ? state.dict[state.current] : null;
  }

  @Selector()
  static inSubmission(state: PersonStateModel): Person {
    return state.inSubmission ? state.dict[state.inSubmission] : null;
  }

  constructor(private store: Store, private personService: PersonService) {
  }

  ngxsOnInit(ctx: StateContext<PersonStateModel>) {
  }

  @Action(LoadPersons)
  loadPersons({patchState}: StateContext<PersonStateModel>) {
    patchState({loading: true, loaded: false});
    this.personService.list().subscribe(
      persons => this.store.dispatch(new LoadPersonsSuccess(persons)),
      err => this.store.dispatch(new BackendError(err)),
    );
  }

  @Action(LoadPersonsSuccess)
  loadPersonsSuccess({patchState, getState}: StateContext<PersonStateModel>, {persons}: LoadPersonsSuccess) {
    const state = getState();
    const dict = {...state.dict};
    const list = [];
    for (const person of persons) {
      if (!dict[person.id]) {
        dict[person.id] = person;
      }
      list.push(person.id);
    }


    patchState({list, dict, loading: false, loaded: true});
  }

  @Action(CreatePerson)
  createPerson({patchState}: StateContext<PersonStateModel>, {detail}: CreatePerson) {
    patchState({loading: true, loaded: false});
    this.personService.create(detail).subscribe(
      () => this.store.dispatch(new CreatePersonSuccess(detail)),
      err => this.store.dispatch(new BackendError(err)),
    );
  }

  @Action(CreatePerson)
  createPersonSuccess({patchState, getState}: StateContext<PersonStateModel>, {detail}: CreatePersonSuccess) {
    const state = getState();
    const list = [...state.list, detail.id];
    const dict = {...state.dict, [detail.id]: detail};
    patchState({
      list,
      dict,
      selected: detail.id,
      loading: false,
      loaded: true
    });
  }

  @Action(LoadCurrentPerson)
  loadCurrentPerson({patchState, dispatch}: StateContext<PersonStateModel>) {
    patchState({
      current: null,
      loading: true,
      loaded: false,
    });
    this.personService.retrieve('current').subscribe(
      person => dispatch(new LoadCurrentPersonSuccess(person)),
      err => this.store.dispatch(new BackendError(err)),
    );
  }

  @Action(LoadCurrentPersonSuccess)
  loadCurrentPersonSuccess({patchState, getState}: StateContext<PersonStateModel>, {detail}: LoadCurrentPersonSuccess) {
    const state = getState();
    patchState({
      current: detail.id,
      dict: {...state.dict, [detail.id]: detail},
      loading: false,
      loaded: true
    });
  }

  @Action(SelectForSubmission)
  selectForSubmission({getState, patchState, dispatch}: StateContext<PersonStateModel>, {id}: SelectForSubmission) {
    patchState({inSubmission: id});
    const person = getState().dict[id];
    if (!person) {
      dispatch(new RetrievePersonDetail(id));
    }
  }

  @Action(RetrievePersonDetail)
  retrievePersonDetail({patchState, dispatch}: StateContext<PersonStateModel>, {id}: RetrievePersonDetail) {
    patchState({loading: true, loaded: false});
    this.personService.retrieve(id).subscribe(
      person => dispatch(new RetrievePersonDetailSuccess(person)),
      err => dispatch(new BackendError(err)),
    );
  }

  @Action(RetrievePersonDetailSuccess)
  retrievePersonDetailSuccess(
    {patchState, getState}: StateContext<PersonStateModel>,
    {detail}: RetrievePersonDetailSuccess
  ) {
    const state = getState();
    const dict = {...state.dict, [detail.id]: detail};
    patchState({dict});
  }

  @Action(LogoutSuccess)
  logoutSuccess({patchState}: StateContext<PersonStateModel>) {
    patchState({
      loaded: false,
      loading: false,
      list: [],
      dict: {},
      selected: null,
      current: null,
      inSubmission: null,
    });
  }
}
