import {BehaviorSubject, Observable} from 'rxjs';
import {distinctUntilChanged, filter, map, pluck, tap} from 'rxjs/operators';
import {Inject, Injectable, Optional} from '@angular/core';
import {HttpClient, HttpEventType, HttpRequest} from '@angular/common/http';
import {defaultRtLoadProgressConfig, ProgressObserverRequestOptions, RtLoadProgressConfig, RtLoadProgressConfigToken} from './symbols';
import {defaults} from 'lodash';

@Injectable()
export class RtLoadProgressService {
  /**
   * Contain service options.
   * Default options will be used if no options provided by user.
   */
  private serviceOptions: RtLoadProgressConfig;

  /**
   * Object contain data about all loading.
   * [id] - unique identifier, [value] - progress in percents (xx.xx%).
   * Emit value on every change.
   */
  private _loadProgress: {[id: string]: number} = {};

  private _requestIdPlaceholder = 0;
  /**
   * Contain actual data about current progress status.
   * Emit event on every change.
   */
  private progressChangeObserver$ = new BehaviorSubject<{[id: string]: number}>(null);

  constructor(
    private http: HttpClient,
    @Inject(RtLoadProgressConfigToken) @Optional() injectedOptions: RtLoadProgressConfig
  ) {
    this.serviceOptions = defaults({}, injectedOptions, defaultRtLoadProgressConfig);
  }

  /**
   * Generate and return unique id.
   */
  get generatedRequestId(): string {
    let currentId = this._requestIdPlaceholder.toString();

    /** Increase id placeholder until it will be unique. */
    while (this.loadProgress[currentId] !== undefined) {
      this._requestIdPlaceholder++;
      currentId = this._requestIdPlaceholder.toString();
    }
    return currentId;
  }

  /**
   * Return observer that emits value on every progress status change.
   * Where value = average progress status for all items.
   */
  public get generalProgress$(): Observable<number> {
    return this.progressChangeObserver$.asObservable().pipe(filter(progressObj => !!progressObj), map(progressObj => {
      const progressObjKeys = Object.keys(progressObj);
      /** No items in status object => load for all items finished. */
      if (progressObjKeys.length === 0) {
        return 100;
      }

      /** Count and return average progress. */
      let generalProgressSum = 0;
      progressObjKeys.forEach(key => {
        generalProgressSum += progressObj[key];
      });
      const averageProgress = generalProgressSum / progressObjKeys.length;
      return this.makeSuitablePercent(averageProgress);
    }), distinctUntilChanged());
  }

  /**
   * Return observer that emits whole status object on every progress status change.
   */
  public get loadingStatusObj$(): Observable<{[id: string]: number}> {
    return this.progressChangeObserver$.asObservable().pipe(filter(progressObj => !!progressObj));
  }

  private get loadProgress(): {[id: string]: number} {
    return this._loadProgress;
  }

  private set loadProgress(progressObj: {[id: string]: number}) {
    this._loadProgress = progressObj;
    this.progressChangeObserver$.next(progressObj);
  }

  public addProgressObserver<T>(providedData: ProgressObserverRequestOptions): Observable<T> {
    /** Set report progress to true for provided options. */
    const initHttpRequestOptions = {
      ...providedData.httpOptions, reportProgress: true,
    };

    /** Create new request using provided data. */
    const newRequest = new HttpRequest(providedData.method, providedData.url, initHttpRequestOptions);

    /** Save data about loading in service using unique identifier. */
    const requestId = providedData.id ? providedData.id : this.generatedRequestId;
    if (this.loadProgress[requestId] !== undefined) {
      console.warn(`Request with provided id (${requestId}) already exist. This may happen if using automatically generated id and
      custom id simultaneously or provided same id for few requests. Old one will be overwritten.`);
    }
    this.updateLoadProgress(requestId, 0);

    /** Send request, add observer for checking loading progress. */
    return this.http.request<T>(newRequest).pipe(
      tap({
        next: httpEvent => {
          if ('total' in httpEvent) {
            /** Update data about progress only if difference is bigger than minimal step */
            const percentage = this.makeSuitablePercent((
              100 / httpEvent.total
            ) * httpEvent.loaded);
            const difference = percentage - this.loadProgress[requestId];
            if (difference >= this.serviceOptions.PROGRESS_MIN_STEP || percentage > 99) {
              this.updateLoadProgress(requestId, percentage);
            }
            /** Load finished, but progress not equal 100 yet => set it manually. May happen with very small files. */
          } else if (httpEvent.type === HttpEventType.Response && this.loadProgress[requestId] < 100) {
            this.updateLoadProgress(requestId, 100);
          }
        }, error: httpError => {
          console.error(`Error during loading item with id '${requestId}': `, httpError);
        },
      }), /** Return value only once loading finished. */
      filter(httpEvent => httpEvent.type === HttpEventType.Response),

      /** Change output to event.body (to make logic similar as http.get). */
      map(httpEvent => {
        if ('body' in httpEvent) {
          return httpEvent.body as T;
        } else {
          throw new Error('Http event has no attribute "body"'); // error occur, should be caught in tap section
        }
      })
    );
  }

  /**
   * Remove item with provided id from status object.
   * NOTE! This function will trigger calculation for progress, so the value may jump.
   */
  public removeProgressObserver(id: string): void {
    const progressCopy = {...this.loadProgress};
    delete progressCopy[id];
    this.loadProgress = progressCopy;
  }

  /**
   * Clear status object. All items will be removed. Also trigger calculation for progress.
   */
  public removeAllProgressObservers(): void {
    this.loadProgress = {};
    this._requestIdPlaceholder = 0; // No ids in `loadProgress` object => reset placeholder
  }

  /**
   * Return observer that emits value on every progress change for specific item.
   */
  public getSpecificIdProgress$(id: string): Observable<number> {
    return this.progressChangeObserver$.asObservable().pipe(filter(progressObj => !!progressObj),
      pluck(id),
      /** Set progress to 0 if item not exist. This may happen if wrong id provided or item not added yet. */
      map(itemProgress => (
        itemProgress === undefined ? 0 : itemProgress
      )),
      distinctUntilChanged()
    );
  }

  /**
   * To be sure that the result of operations on fractions is suitable.
   */
  private makeSuitablePercent(percent: number) {
    if (percent > 100) {
      percent = 100;
    } else if (percent < 0) {
      percent = 0;
      /** Prevent numbers like 99.74 to be round to 100. */
    } else if (percent > 99 && percent < 100) {
      percent = 99;
    }
    return +percent.toFixed(this.serviceOptions.NUMBER_OF_SYMBOLS_AFTER_COMMA);
  }

  /**
   * Update current progress object using provided data.
   * [id] - unique load identifier, [value] - progress in percents (xx.xx%).
   */
  private updateLoadProgress(
    id: string,
    progress: number
  ) {
    this.loadProgress = {
      ...this.loadProgress, [id]: progress,
    };
  }
}
