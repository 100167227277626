import {NgModule} from '@angular/core';
import {NgxsModule} from '@ngxs/store';
import {PersonState} from './person/person.state';
import {ProfileState} from './profile/profile.state';
import {SubmissionState} from './submission/submission.state';


@NgModule({
  imports: [
    NgxsModule.forFeature([
      PersonState,
      ProfileState,
      SubmissionState,
    ]),
  ],
})
export class MicroservicesModule {}
