import {InjectionToken} from '@angular/core';
import {HttpHeaders, HttpParams} from '@angular/common/http';


export interface RtLoadProgressConfig {
  /**
   * Number describe minimal required difference between previous emitted value and current load progress.
   * Event will not be emitted, if it is less.
   * Use for prevent to many loader updates.
   */
  PROGRESS_MIN_STEP?: number;

  /**
   * How many symbols should be displayed after comma.
   * Any characters over limit will be removed.
   */
  NUMBER_OF_SYMBOLS_AFTER_COMMA?: number;
}

export const defaultRtLoadProgressConfig: RtLoadProgressConfig = {
  PROGRESS_MIN_STEP: 8,
  NUMBER_OF_SYMBOLS_AFTER_COMMA: 2,
};

export const RtLoadProgressConfigToken = new InjectionToken<string>('RtLoadProgressConfig');


export interface ProgressObserverRequestOptions {
  method: 'DELETE' | 'GET' | 'HEAD' | 'JSONP' | 'OPTIONS';
  url: string;
  id?: string;
  httpOptions?: {
    headers?: HttpHeaders;
    reportProgress?: boolean;
    params?: HttpParams;
    responseType?: 'arraybuffer' | 'blob' | 'json' | 'text';
    withCredentials?: boolean;
  };
}
