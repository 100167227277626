import { InjectionToken } from '@angular/core';

interface Scripts {
  name: string;
  src: string;
}

export interface RtDynamicScriptsServiceConfig {
  scripts: Scripts[]
}

export const DYNAMIC_SCRIPTS_CONFIG_TOKEN = new InjectionToken<RtDynamicScriptsServiceConfig>('DYNAMIC_SCRIPTS_CONFIG_TOKEN');
