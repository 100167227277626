import {Observable} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Injectable} from '@angular/core';
import {Store} from '@ngxs/store';
import {Navigate} from '@ngxs/router-plugin';
import {AuthState} from '../../states/auth/auth.state';
import {PermissionsService} from '../../../permissions/permissions.service';

@Injectable({
  providedIn: 'root',
})
export class ImagingCenterGuard {
  constructor(private store: Store, private userCan: PermissionsService) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.store.select(AuthState.getUser).pipe(
      filter(x => !!x),
      map(() => {
        if (this.userCan.uploadAsImagingCenter) {
          return true;
        } else {
          this.store.dispatch(new Navigate(['/']));
          return false;
        }
      })
    );
  }
}
